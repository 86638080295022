import 'src/components/atoms/logo/index.scss';
import Image from 'src/components/atoms/image';
import { Link } from 'gatsby';
import { useConfig } from 'src/hooks';

import React from 'react';

interface Props {
  src: string;
}

export default ({ src }: Props) => {
  const { homeIconRedirectUrl } = useConfig();
  const isAllied = typeof window !== 'undefined' && sessionStorage.getItem('isAllied') === 'true';
  const isExpressCheckoutFromSession = typeof window !== 'undefined' && sessionStorage.getItem('isExpressCheckout') === 'true';

  return (
    isAllied || isExpressCheckoutFromSession ? <span className='logo'>
      <Image src={src} alt='Site Logo' />
    </span>
      :
      <Link className='logo' to={homeIconRedirectUrl}>
        <Image src={src} alt='Site Logo' />
      </Link>
  );
};
